<template lang="pug">
v-dialog.position-relative.dialog(v-bind="$attrs", v-on="$listeners")
  .close_btn_container
    v-icon.close_btn(@click="closeDialog", large, color="black") mdi-close
  v-container.position-relative.content_container(ref="content")
    v-row(justify="center")
      .uvideo
        youtube(
          ref="youtube",
          @ready="ready",
          @ended="ended",
          resize,
          fitParent,
          nocookie,
          style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
        )
    v-row(justify="space-between")
      .reference
      v-switch.play_all(v-model="autoPlay", label="自動播放", color="#487aa4")
    v-row
      .play_table
        .play_head
          .head.play_play
          .head.play_time 時間
          .head.play_player 球員
          .head.play_team 隊伍
          .head.play_event 事件
        .play_content
          .play_item(
            v-for="(event, index) in eventList",
            :key="event.id",
            :class="{ selected: eventIndex == index }",
            @click="onRowClick(index)"
          )
            .content.play_play
              v-icon.play(x-small, color="white") mdi-play
            .content.play_time {{ event.video_start_at }}
            .content.play_player {{ event.player_name }}
            .content.play_team {{ event.team_name }}
            .content.play_event {{ dialogEvent }}
    v-row
      v-col
        v-pagination(
          v-model="currentPage",
          :length="totalPages",
          prev-icon="mdi-menu-left",
          next-icon="mdi-menu-right",
          circle,
          @input="onPaginationInput"
        )
</template>

<script>
import { getEventByPosition, getEventByStat } from "@/api/event";
import { getIdFromUrl } from "vue-youtube";

export default {
  name: "EventVideoDialog",
  components: {},
  props: {
    seasonSegmentId: {
      type: Number,
      required: false,
      default: 0,
    },
    gameId: {
      type: Number,
      required: false,
      default: 0,
    },
    quarter: {
      type: Number,
      required: false,
      default: 0,
    },
    teamId: {
      type: Number,
      required: false,
      default: 0,
    },
    playerId: {
      type: Number,
      required: false,
      default: 0,
    },
    stat: {
      type: String,
      required: false,
      default: "",
    },
    position: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      autoPlay: true,
      eventIndex: 0,

      eventList: [],

      currentPage: 1,
      totalPages: 1,
      pageSize: 10,
    };
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
    dialogEvent() {
      if (this.position && !this.stat) {
        switch (this.position) {
          case "PAINT":
            return "禁區";
          case "TOP_OF_THE_ARC_2_PTS":
            return "弧頂(兩分)";
          case "TOP_OF_THE_ARC_3_PTS":
            return "弧頂(三分)";
          case "LEFT_BASELINE_2_PTS":
            return "左邊底線(兩分)";
          case "RIGHT_BASELINE_2_PTS":
            return "右邊底線(兩分)";
          case "LEFT_BASELINE_3_PTS":
            return "左邊底線(三分)";
          case "RIGHT_BASELINE_3_PTS":
            return "右邊底線(三分)";
          case "LEFT_WING_2_PTS":
            return "左邊側翼(兩分)";
          case "RIGHT_WING_2_PTS":
            return "右邊側翼(兩分)";
          case "LEFT_WING_3_PTS":
            return "左邊側翼(三分)";
          case "RIGHT_WING_3_PTS":
            return "右邊側翼(三分)";
        }
      }
      if (this.stat && !this.position) {
        switch (this.stat) {
          case "pts":
            return "得分";
          case "reb":
            return "籃板";
          case "ast":
            return "助攻";
          case "stl":
            return "抄截";
          case "blk":
            return "阻攻";
          case "fgm":
            return "命中";
          case "fga":
            return "出手";
          case "fg2m":
            return "兩分命中";
          case "fg2a":
            return "兩分出手";
          case "fg3m":
            return "三分命中";
          case "fg3a":
            return "三分出手";
          case "ftm":
            return "上罰球線";
          case "fta":
            return "上罰球線";
          case "oreb":
            return "進攻籃板";
          case "dreb":
            return "防守籃板";
          case "tov":
            return "失誤";
          case "pf":
            return "犯規";
        }
      }
      return "";
    },
  },
  watch: {
    "$attrs.value"(value) {
      if (value) this.getEventList();
      else if (!value && this.$refs.youtube) this.player.stopVideo();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {},
    closeDialog() {
      this.$emit("input", false);
    },
    getEventList() {
      if (this.position && !this.stat) this.getEventByPositionApi();
      else if (!this.position && this.stat) this.getEventByStatApi();
    },
    async getEventByStatApi() {
      const params = {
        season_segment_id: this.seasonSegmentId ? this.seasonSegmentId : null,
        game_id: this.gameId ? this.gameId : null,
        quarter: this.quarter ? this.quarter : null,
        team_id: this.teamId ? this.teamId : null,
        player_id: this.playerId ? this.playerId : null,
        stat: this.stat,
        page: this.currentPage,
        page_size: this.pageSize,
      };
      const response = await getEventByStat(params);
      this.eventList = response.data.results;
      this.totalPages = Math.ceil(response.data.count / this.pageSize);
      this.ready();
    },
    async getEventByPositionApi() {
      const params = {
        season_segment_id: this.seasonSegmentId ? this.seasonSegmentId : null,
        game_id: this.gameId ? this.gameId : null,
        team_id: this.teamId ? this.teamId : null,
        player_id: this.playerId ? this.playerId : null,
        position: this.position,
        page: this.currentPage,
        page_size: this.pageSize,
      };
      const response = await getEventByPosition(params);
      this.eventList = response.data.results;
      this.totalPages = Math.ceil(response.data.count / this.pageSize);
      this.ready();
    },
    onPaginationInput() {
      this.getEventList();
    },
    ready() {
      if (this.eventList && this.eventList.length > 0) {
        this.eventIndex = 0;
        const event = this.eventList[this.eventIndex];
        this.player.cueVideoById({
          videoId: getIdFromUrl(event.youtube_url),
          startSeconds: this.getStartSeconds(event),
          endSeconds: this.getEndSeconds(event),
        });
      }
    },
    async ended() {
      // To solve ended triggering twice
      const bufferedPercentage = await this.player.getVideoLoadedFraction();
      if (this.autoPlay === true && bufferedPercentage > 0) {
        this.eventIndex++;
        this.playVideo();
      }
    },
    onRowClick(index) {
      this.eventIndex = index;
      this.playVideo();
    },
    playVideo() {
      const event = this.eventList[this.eventIndex];
      if (event === undefined) return;
      this.player.loadVideoById({
        videoId: getIdFromUrl(event.youtube_url),
        startSeconds: this.getStartSeconds(event),
        endSeconds: this.getEndSeconds(event),
      });
    },
    getStartSeconds(event) {
      let startSeconds = 0;
      // 罰球後籃板
      if (this.stat === "reb" && event.fta > 0)
        startSeconds =
          this.getSeconds(event.video_end_at) - event.video_duration;
      else
        startSeconds =
          this.getSeconds(event.video_start_at) - event.video_duration;
      return startSeconds;
    },
    getEndSeconds(event) {
      let endSeconds = 0;
      if (event.fta > 0) endSeconds = this.getSeconds(event.video_end_at) + 2;
      else endSeconds = this.getSeconds(event.video_start_at) + 2;
      return endSeconds;
    },
    getSeconds(time) {
      const timeArray = time.split(":");
      let milliseconds = Date.UTC(
        1970,
        0,
        1,
        timeArray[0],
        timeArray[1],
        timeArray[2]
      );
      return milliseconds / 1000;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/dialog_play_video";
@import "@/assets/style/common/pagination";
.dialog {
  background: white;
  height: 100%;
  width: 810px;
}
</style>
