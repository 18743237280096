import request from "@/utils/request";

export function getEventByStat(params) {
  return request({
    url: "/get_event_by_stat/",
    method: "get",
    params,
  });
}

export function getEventByPosition(params) {
  return request({
    url: "/get_event_by_position/",
    method: "get",
    params,
  });
}
