<template lang="pug">
.line_up
  v-container
    v-row.table_row
      v-col#sticky-nav.table_col.table.position-relative.mt-4(cols="12")
        table
          thead.top_head
            sticky-column.bdr2(colspan="1", :offset="0")
            th(colspan="2")
            th(colspan="5")
            th.bdr(colspan="3") 投籃
            th.bdr(colspan="3") 兩分
            th.bdr(colspan="3") 三分
            th.bdr(colspan="3") 罰球
            th.bdr(colspan="2") 籃板
            th(colspan="4")
          thead.bottom_head
            sticky-column.bdr2(colspan="1", title="球員", :offset="0")
            th(
              v-for="header in boxTableHeaders",
              :key="header.key",
              style="cursor: pointer",
              @click="sortClick(header.key)",
              :class="getHeaderClassObject(header)"
            )
              span {{ header.name }}
              font-awesome-icon.ml-2(:icon="getTableSortIcon(header.key)")
          tbody(v-if="players.length > 0")
            tr(v-for="player in sortedPlayers", :key="player.name")
              sticky-column.bdl.bdr2.hover.clickable(
                colspan="1",
                :offset="0",
                :title="player.name",
                @click.native="playerClick(player.player_id)"
              )
              td(
                v-for="header in boxTableHeaders",
                :key="header.key",
                :class="getDataClassObject(header, player.overall[header.key])",
                @click="dataClick(header.key, header.link, player.player_id)"
              )
                span {{ player.overall[header.key] }}
  event-video-dialog(
    v-model="isDialogShow",
    :seasonSegmentId="seasonSegmentId",
    :playerId="playerId",
    :stat="stat"
  )
</template>

<script>
import { getTeamRoster } from "@/api/league";
import StickyColumn from "@/components/common/Table/StickyColumn";
import EventVideoDialog from "@/components/league/EventVideoDialog";

export default {
  name: "LeagueTeamRoster",
  components: {
    StickyColumn,
    EventVideoDialog,
  },
  data() {
    return {
      boxTableHeaders: [
        {
          name: "出賽",
          key: "gp",
          border: false,
          bold: false,
          link: false,
        },
        {
          name: "時間",
          key: "min",
          border: true,
          bold: false,
          link: false,
        },
        {
          name: "得分",
          key: "pts",
          border: false,
          bold: true,
          link: true,
        },
        {
          name: "籃板",
          key: "reb",
          border: false,
          bold: true,
          link: true,
        },
        {
          name: "助攻",
          key: "ast",
          border: false,
          bold: true,
          link: true,
        },
        {
          name: "抄截",
          key: "stl",
          border: false,
          bold: true,
          link: true,
        },
        {
          name: "阻攻",
          key: "blk",
          border: true,
          bold: true,
          link: true,
        },
        {
          name: "命中",
          key: "fgm",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "出手",
          key: "fga",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "命中率%",
          key: "fg_pct",
          border: true,
          bold: false,
        },
        {
          name: "命中",
          key: "fg2m",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "出手",
          key: "fg2a",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "命中率%",
          key: "fg2_pct",
          border: true,
          bold: false,
        },
        {
          name: "命中",
          key: "fg3m",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "出手",
          key: "fg3a",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "命中率%",
          key: "fg3_pct",
          border: true,
          bold: false,
        },
        {
          name: "命中",
          key: "ftm",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "出手",
          key: "fta",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "命中率%",
          key: "ft_pct",
          border: true,
          bold: false,
        },
        {
          name: "進攻",
          key: "oreb",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "防守",
          key: "dreb",
          border: true,
          bold: false,
          link: true,
        },
        {
          name: "失誤",
          key: "tov",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "犯規",
          key: "pf",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "正負值",
          key: "plus_minus",
          border: false,
          bold: false,
          link: false,
        },
        {
          name: "效率值",
          key: "eff",
          border: true,
          bold: false,
          link: false,
        },
      ],
      players: [],
      sortedPlayers: [],

      sortKey: "",
      sortOrder: "",

      playerId: 0,
      isDialogShow: false,
      stat: "",
    };
  },
  computed: {
    leagueId() {
      return Number(this.$route.params.leagueId);
    },
    seasonSegmentId() {
      return Number(this.$route.params.seasonSegmentId);
    },
    teamId() {
      return Number(this.$route.params.teamId);
    },
  },
  watch: {
    seasonSegmentId() {
      this.initData();
    },
  },

  created() {
    this.initData();
  },
  methods: {
    async initData() {
      if (this.leagueId < 1 || this.seasonSegmentId < 1 || this.teamId < 1)
        return;
      this.players = [];
      this.sortedPlayers = [];
      this.sortKey = "";
      this.sortOrder = "";
      await this.getTeamRosterApi();
    },
    async getTeamRosterApi() {
      const params = {
        team_id: this.teamId,
        season_segment_id: this.seasonSegmentId,
      };
      const response = await getTeamRoster(params);
      this.players = response.data.players;
      this.sortedPlayers = Array.from(this.players);
    },
    getHeaderClassObject(header) {
      return {
        sort_up: this.sortKey == header.key && this.sortOrder == "ASC",
        sort_down: this.sortKey == header.key && this.sortOrder == "DESC",
        bdr: header.border,
      };
    },
    getDataClassObject(header, value) {
      let classObject = {};
      if (header.link && header.bold) classObject.link_deep = true;
      else if (header.link) classObject.link = true;
      else if (value <= 0) classObject.gray_out_heavy = true;
      classObject.bdr = header.border;
      return classObject;
    },
    getTableSortIcon(key) {
      if (key == this.sortKey) {
        if ("ASC" == this.sortOrder) {
          return ["fas", "caret-up"];
        } else {
          return ["fas", "caret-down"];
        }
      } else {
        return ["fas", "sort"];
      }
    },
    sortClick(key) {
      if (key !== this.sortKey) {
        this.sortKey = key;
        this.sortOrder = "DESC";
        this.sortPlayers();
      } else {
        if (this.sortOrder == "DESC") {
          this.sortOrder = "ASC";
          this.sortPlayers();
        } else {
          this.sortKey = "";
          this.sortOrder = "";
          this.sortedPlayers = Array.from(this.players);
        }
      }
    },
    sortPlayers() {
      this.sortedPlayers.sort((a, b) => {
        if (this.sortOrder === "DESC")
          return b.overall[this.sortKey] - a.overall[this.sortKey];
        else return a.overall[this.sortKey] - b.overall[this.sortKey];
      });
    },
    dataClick(stat, link, playerId) {
      if (!link) return;
      this.stat = stat;
      this.playerId = playerId;
      this.isDialogShow = true;
    },
    playerClick(playerId) {
      this.$router.push({
        name: "LeaguePlayer",
        params: {
          leagueId: this.leagueId,
          seasonSegmentId: this.seasonSegmentId,
          playerId: playerId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/dialog_play_video";

a.player {
  color: #3081d3;
  text-decoration: underline;
  display: block;
}

.position_card {
  p.text-subtitle {
    margin-bottom: 0;
  }
}

#sticky-nav {
  table {
    .top_head {
      th {
        top: 0;
      }
    }
    .bottom_head {
      th {
        top: 38px;
      }
    }
  }
}

@media (max-width: 599px) {
  #sticky-nav {
    table {
      .top_head {
        th {
          top: 0;
        }
      }

      .bottom_head {
        th {
          top: 31.69px;
        }
      }
    }
  }
}
</style>
